import PropTypes from "prop-types";
import React, { Component } from "react";
import { Helmet } from 'react-helmet'

class PageHelmet extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.pageTitle} - นาจันทร์สว่างฟาร์มสเตย์ยโสธร</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <meta property="og:image" content="/assets/images/logo/logo.webp" />
                    <meta name="description" content="ติดต่อ NaChnasawang Farmstay - ฟาร์มสเตย์ธรรมชาติที่ยโสธร พร้อมกิจกรรมวิถีชีวิตเกษตรอินทรีย์ โทรหาเราหรือส่งข้อความเพื่อจองที่พักและสอบถามข้อมูลเพิ่มเติม" />
                    <meta name="keywords" content="ฟาร์มสเตย์, นาจันทร์สว่าง, ยโสธร, ที่พักธรรมชาติ, ท่องเที่ยวเกษตรอินทรีย์" />
                    <meta name="author" content="NaChansawang Farmstay" />
                </Helmet>
            </React.Fragment>
        )
    }
}
PageHelmet.propTypes = {
    title: PropTypes.string
};
export default PageHelmet;
