import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaFacebook, FaLine, FaPhoneAlt, FaMapMarkedAlt } from "react-icons/fa";

const logoUrl = <img src="/assets/images/logo/logo-light.webp" alt="นาจันทร์สว่างฟาร์มสเตย์ยโสธร" />;



// const SocialShare = [
//     { Social: <FaFacebookF />, link: 'https://www.facebook.com/farmchansawangyasothon' },
//     { Social: <FaInstagram />, link: 'https://www.instagram.com/farmchansawangyasothon' },
//     { Social: <SiTiktok />, link: 'https://www.tiktok.com/@farmchansawangyasothon' },
//     { Social: <FaLine />, link: 'https://lin.ee/htkgF3M' },
// ]

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer-area footer-style-01 bg_color--2">
                    {/* Start Footer Area  */}
                    <div className="footer-wrapper ptb--70">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="ft-text">
                                        <div className="logo">
                                            <Link to="/">
                                                {logoUrl}
                                            </Link>
                                        </div>
                                        <p>จำหน่ายทองรูปพรรณทองคำแท้ 99.99%,96.5%
                                            จากเยาวราช มี 2 สาขาในจังหวัดยโสธร สินค้าทุกชิ้น
                                            มีใบรับประกัน</p>
                                        <p>Copyright © 2023 HANG THONG URAISIN CO.,LTD. <br />All rights reserved.</p>
                                    </div>
                                </div>

                                {/* Start Single Widget  */}
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt_sm--20">
                                    <div className="footer-link">
                                        <h1>นาจันทร์สว่างฟาร์มสเตย์ยโสธร</h1>
                                        <ul className="ft-link">
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/product">Life @ Chansawang</Link></li>
                                            <li><Link to="/goldbar">Explore</Link></li>
                                            <li><Link to="/goldsaver">Media</Link></li>
                                            <li><Link to="/about">Contract</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Widget  */}

                                {/* Start Single Widget  */}
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt_sm--20">
                                    <div className="footer-link">
                                        <h1>ช่องทางติดต่อ สาขา 1</h1>
                                        <ul className="ft-link">
                                            {/* <li>โทร <a href="mailto:hr@example.com">045 971 313</a></li> */}


                                            <li><a href="tel:+6645 712 548" class="button mt--10"><FaPhoneAlt className="icon" />โทร 045 712 548</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/farmchansawangyasothon" class="button mt--15"><FaFacebook className="icon" />Facebook</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://lin.ee/htkgF3M" class="button mt--15"><FaLine className="icon" />Line</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/SCnw5WTjTMVwUUaEA" class="button mt--15"><FaMapMarkedAlt className="icon" />แผนที่ Google Map</a></li>
                                        </ul>

                                        {/* <div className="social-share-inner mt--20">
                                            <ul className="social-share-footer social-style--2 d-flex justify-content-start liststyle">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                                {/* End Single Widget  */}

                                {/* Start Single Widget  */}
                                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-12 mt_sm--20">
                                    <div className="footer-link">
                                        <h1>ช่องทางติดต่อ สาขา 2</h1>
                                        <ul className="ft-link">
                                            {/* <li>โทร <a href="mailto:hr@example.com">045 971 313</a></li> */}


                                            <li><a href="tel:+6645 971 313" class="button mt--10"><FaPhoneAlt className="icon" />โทร 045 971 313</a></li>
                                            <li> <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/farmchansawangyasothon2" class="button mt--15"><FaFacebook className="icon" />Facebook</a></li>
                                            <li> <a target="_blank" rel="noopener noreferrer" href="https://lin.ee/htkgF3M" class="button mt--15"><FaLine className="icon" />Line</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/qGWpLBRejZACYKtJ8" class="button mt--15"><FaMapMarkedAlt className="icon" />แผนที่ Google Map</a></li>
                                        </ul>

                                        {/* <div className="social-share-inner mt--20">
                                            <ul className="social-share-footer social-style--2 d-flex justify-content-start liststyle">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                                {/* End Single Widget  */}
                            </div>
                        </div>
                    </div>
                    {/* End Footer Area  */}
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;