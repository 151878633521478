import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { slideSlick } from "./script";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { FaInstagram, FaLine } from "react-icons/fa";
import { SiTiktok } from "react-icons/si";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ShortcutMenu from "../component/HomeLayout/homeOne/ShortcutMenu";

import { portfolioSlick2 } from "./script";

const Portfolio_image0 = <img src="/assets/images/portfolio/portfolio-g.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image1 = <img src="/assets/images/portfolio/1.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/2.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/3.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/4.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/5.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/6.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image7 = <img src="/assets/images/portfolio/7.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/8.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/9.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image10 = <img src="/assets/images/portfolio/10.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image11 = <img src="/assets/images/portfolio/11.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image12 = <img src="/assets/images/portfolio/12.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image13 = <img src="/assets/images/portfolio/13.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image14 = <img src="/assets/images/portfolio/14.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image15 = <img src="/assets/images/portfolio/15.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image16 = <img src="/assets/images/portfolio/16.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image17 = <img src="/assets/images/portfolio/17.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image18 = <img src="/assets/images/portfolio/18.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image19 = <img src="/assets/images/portfolio/19.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image20 = <img src="/assets/images/portfolio/20.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image21 = <img src="/assets/images/portfolio/21.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;   
const Portfolio_image22 = <img src="/assets/images/portfolio/22.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image23 = <img src="/assets/images/portfolio/23.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image24 = <img src="/assets/images/portfolio/24.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image25 = <img src="/assets/images/portfolio/25.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image26 = <img src="/assets/images/portfolio/26.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image27 = <img src="/assets/images/portfolio/27.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image28 = <img src="/assets/images/portfolio/28.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image29 = <img src="/assets/images/portfolio/29.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image30 = <img src="/assets/images/portfolio/30.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image31 = <img src="/assets/images/portfolio/31.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image32 = <img src="/assets/images/portfolio/32.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image33 = <img src="/assets/images/portfolio/33.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image34 = <img src="/assets/images/portfolio/34.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image35 = <img src="/assets/images/portfolio/35.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image36 = <img src="/assets/images/portfolio/36.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image37 = <img src="/assets/images/portfolio/37.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image38 = <img src="/assets/images/portfolio/38.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image40 = <img src="/assets/images/portfolio/42.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image41 = <img src="/assets/images/portfolio/43.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image42 = <img src="/assets/images/portfolio/44.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image43 = <img src="/assets/images/portfolio/45.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image44 = <img src="/assets/images/portfolio/46.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image45 = <img src="/assets/images/portfolio/47.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image46 = <img src="/assets/images/portfolio/48.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image47 = <img src="/assets/images/portfolio/49.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;
const Portfolio_image49 = <img src="/assets/images/portfolio/50.webp" alt="กิจกรรมที่นาจันทร์สว่าง" />;






const AccessoriesList0_image = <img src="/assets/images/portfolio/portfolio-g.webp" alt="อนุทิน ชื่นชม นาจันทร์สว่าง ฟาร์มสเตย์รูปแบบใหม่ในยโสธร" />;
const AccessoriesList1_image = <img src="/assets/images/portfolio/4.webp" alt="โคตรคูลมาถ่ายทำที่นาจันทร์สว่าง" />;
const AccessoriesList2_image = <img src="/assets/images/portfolio/18.webp" alt="ผู้ว่ามาเยือนนาจันทร์สว่าง" />;
const AccessoriesList3_image = <img src="/assets/images/portfolio/11.webp" alt="รางวัล sme softpower star" />;
const AccessoriesList4_image = <img src="/assets/images/portfolio/50.webp" alt="รับรางวัล 5 ดาว STGs STAR จากการผ่านเกณฑ์ Sustainable Tourism Goals ถึง 14 ข้อจากทั้งหมด 17 ข้อ" />;
const AccessoriesList5_image = <img src="/assets/images/portfolio/21.webp" alt="โรงเรียนบ้านดอนมะยาง กม.3" />;
const AccessoriesList6_image = <img src="/assets/images/portfolio/2.webp" alt="โรงเรียนบ้านยางเดี่ยวหนองถ่ม" />;
const AccessoriesList8_image = <img src="/assets/images/portfolio/51.webp" alt="กองพันทหารราบที่ 2 กรมทหารราบที่ 16" />;
const AccessoriesList10_image = <img src="/assets/images/portfolio/15.webp"  alt="เกษตรอำเภอเมือง" />;
const AccessoriesList11_image = <img src="/assets/images/portfolio/52.webp" alt="Yo! Organic Youth Camp: เกษตรอินทรีย์เพื่อคนรุ่นใหม่" />;


const AccessoriesList = [
    {
        image: AccessoriesList0_image
    },
    {
        image: AccessoriesList1_image,
        title: 'โคตรคูลถ่ายทำที่นาจันทร์สว่าง'
    },
    {
        image: AccessoriesList2_image,
        title: 'ผู้ว่ามาเยือน'
    },
    {
        image: AccessoriesList3_image,
        title: 'รางวัล SME SOFTPOWER STAR'
    },
    {
        image: AccessoriesList4_image,
        title: 'รางวัล 5 ดาว STGs STAR'
    },
    {
        image: AccessoriesList5_image,
        title: 'ทันศึกษาโรงเรียนโรงเรียนบ้านดอนมะยาง กม.3'
    },
    {
        image: AccessoriesList6_image,
        title: 'ทันศึกษาโรงเรียนบ้านยางเดี่ยวหนองถ่ม'
    },
    {
        image: AccessoriesList8_image,
        title: 'กองพันทหารราบที่ 2 กรมทหารราบที่ 16',
    },
    {
        image: AccessoriesList11_image,
        title: 'Yo! Organic Youth Camp: เกษตรอินทรีย์เพื่อคนรุ่นใหม่',
    }
]

const SlideList = [
    { bgImage: 'bg_image--1' },
    { bgImage: 'bg_image--2' },
    { bgImage: 'bg_image--3' },
    { bgImage: 'bg_image--4' },
    { bgImage: 'bg_image--5' }
]

const PortfolioList2 = [
    { image: Portfolio_image1, },
    { image: Portfolio_image2, },
    { image: Portfolio_image3, },
    { image: Portfolio_image4, },
    { image: Portfolio_image5, },
    { image: Portfolio_image6, },
    { image: Portfolio_image7, },
    { image: Portfolio_image8, },
    { image: Portfolio_image9, },
    { image: Portfolio_image10, },
    { image: Portfolio_image11, },
    { image: Portfolio_image12, },
    { image: Portfolio_image13, },
    { image: Portfolio_image14, },
    { image: Portfolio_image15, },
    { image: Portfolio_image16, },
    { image: Portfolio_image17, },
    { image: Portfolio_image18, },
    { image: Portfolio_image19, },
    { image: Portfolio_image20, },
    { image: Portfolio_image21, },
    { image: Portfolio_image22, },
    { image: Portfolio_image23, },
    { image: Portfolio_image24, },
    { image: Portfolio_image25, },
    { image: Portfolio_image26, },
    { image: Portfolio_image27, },
    { image: Portfolio_image28, },
    { image: Portfolio_image29, },
    { image: Portfolio_image30, },
    { image: Portfolio_image31, },
    { image: Portfolio_image32, },
    { image: Portfolio_image33, },
    { image: Portfolio_image34, },
    { image: Portfolio_image35, },
    { image: Portfolio_image36, },
    { image: Portfolio_image37, },
    { image: Portfolio_image38, },
    { image: Portfolio_image40, },
    { image: Portfolio_image41, },
    { image: Portfolio_image42, },
    { image: Portfolio_image43, },
    { image: Portfolio_image44, },
    { image: Portfolio_image45, },
    { image: Portfolio_image46, },
    { image: Portfolio_image47, },
    { image: Portfolio_image49, },


]


class Startup extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };
    render() {
        return (
            <Fragment>
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value, index) => (
                                // Start Single Slider 
                                <div className="img" >
                                    <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    </div>
                                </div>
                                // End Single Slider 
                            ))}
                        </Slider>
                    </div>
                </div >
                {/* End Slider Area   */}

                <div className="mt--40 mb--10 bg_color--1">
                    <div className="container">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">

                                    <a href="https://www.tatstar.org/company/STAR-B00708" target="_blank" rel="noopener noreferrer"><img className="w-100" rel="noopener noreferrer" src="/assets/images/contact/reward-1.webp" alt="นาจันทร์สว่างฟาร์มสเตย์ยโสธร ได้รางวัล 5 ดาว STGs STAR" /></a>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <a href="https://www.facebook.com/share/p/1RiWFYZJK9/" target="_blank" rel="noopener noreferrer"><img className="w-100" rel="noopener noreferrer" src="/assets/images/contact/reward-2.webp" alt="นาจันทร์สว่างฟาร์มสเตย์ยโสธร ได้รางวัล sme softpower star" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >


                {/* Start Shortcut Menu */}
                {/*  <div className="shortcut-menu-area bg_color--1">
                    <div className="sacousel-inner mb--5">
                        <ShortcutMenu />
                    </div>
                </div>
                {/* End Shortcut Menu */}


                {/* Product List Menu */}
                 <div className="product-area">
                    <div className="product-menu-wrapper">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 className="title">Activity And Partner</h2>
                                </div>
                            </div>
                        </div>
                            <div className="row">
                                {AccessoriesList.map((value, index) => (
                                    <div className="block col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" key={index}>
                                        <div className="im_product-menu">
                                            <div className="thumbnail_inner">
                                                <div className="thumbnail">
                                                    <Link to={value.link}>
                                                        {value.image}
                                                    </Link>
                                                </div>
                                                <div className="content">
                                                    <div className="inner">
                                                        <div className="product_heading">
                                                            <h4 className="title">{value.title}<br />{value.titleTwo}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className="transparent_link" to={value.link} aria-label="นาจันทร์สว่างฟาร์มสเตย์ยโสธร"></Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {/* END Product List Menu */}

                {/* <div className="rn-button-wrapper">
                    <div className="container">
                        <div className="col-lg-12">
                            <div className="row button-group mt--20 text-center">
                                <div className="col-lg-4">
                                    <a className="btn-default color-instagram module-border-instagram size-md" href="https://www.instagram.com/farmchansawangyasothon"><FaInstagram className="icon"/> Instagram</a>
                                </div>
                                <div className="col-lg-4">
                                    <a className="btn-default color-tiktok size-md" href="https://www.tiktok.com/@farmchansawangyasothon"><SiTiktok className="icon"/> Tiktok</a>
                                </div>
                                <div className="col-lg-4">
                                    <a className="btn-default color-line size-md" href="https://lin.ee/htkgF3M"><FaLine className="icon"/> Line</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}



                {/* Start About Area */}
                <div className="about-area ptb--40 mt--50 bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--20 align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="about-inner inner">
                                        <div className="section-title mt--20">
                                            <h2 className="title">นาจันทร์สว่างฟาร์มสเตย์ยโสธร</h2>
                                            <p className="description">นาจันทร์สว่าง ฟาร์มสเตย์ยโสธร เกิดจากแนวคิดที่ต้องการสร้างพื้นที่แห่งความสุขและการเรียนรู้เกี่ยวกับการเกษตรที่ยั่งยืนในจังหวัดยโสธร ภายใต้แนวคิด "เกษตรอินทรีย์ วิถีชุมชน คนอยู่กับธรรมชาติ" โดยมีเป้าหมายสำคัญคือการเปิดโอกาสให้ผู้ที่มาเยือน ได้สัมผัสกับชีวิตการทำเกษตรกรรมแบบอินทรีย์อย่างใกล้ชิด และเรียนรู้วิถีชุมชนอีสานอย่างลึกซึ้งผ่านกิจกรรมและประสบการณ์จริง

ที่นาจันทร์สว่าง ฟาร์มสเตย์ ผู้มาเยือนจะได้สัมผัสกับบรรยากาศที่อบอุ่น เรียบง่าย แต่เต็มไปด้วยเสน่ห์ของชนบทอีสาน มีการจัดกิจกรรมต่างๆ เช่น ปลูกข้าวอินทรีย์ เก็บผักสวนครัว เลี้ยงสัตว์ ทำอาหารท้องถิ่น และร่วมกิจกรรมประเพณีของชุมชนท้องถิ่น นอกจากนี้ยังเปิดโอกาสให้ผู้เข้าพักได้เรียนรู้และฝึกปฏิบัติเกษตรกรรมแบบพึ่งพาตนเอง เพื่อสร้างแรงบันดาลใจในการกลับไปประยุกต์ใช้ในชีวิตประจำวันได้จริง

นาจันทร์สว่าง ฟาร์มสเตย์ ยังมุ่งเน้นให้เกิดการพัฒนาและรักษาคุณค่าทางวัฒนธรรมและสิ่งแวดล้อม โดยยึดหลักการทำเกษตรกรรมแบบอินทรีย์ ปลอดสารพิษ รักษาระบบนิเวศ และช่วยเหลือชุมชนในการสร้างรายได้อย่างยั่งยืน ถือเป็นสถานที่แห่งความสุขที่ทำให้ผู้มาเยือนสามารถสัมผัสชีวิตช้าๆ ใกล้ชิดธรรมชาติ และรับรู้คุณค่าของวิถีเกษตรกรรมแบบดั้งเดิมที่ผสานกับความทันสมัยได้อย่างลงตัว</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                    <div className="about-area ptb--40 mt--50 bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--20 align-items-center">
                               
                                <div className="col-lg-6 col-md-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                             <h2 className="title">อนุทิน กล่าวชื่นชม นาจันทร์สว่าง ฟาร์มสเตย์รูปแบบใหม่ในยโสธร</h2> 
                                            <p className="description">(22 มีนาคม 2568) นายอนุทิน ชาญวีรกูล รองนายกรัฐมนตรี และรัฐมนตรีว่าการกระทรวงมหาดไทย  กล่าวชื่นชม นาจันทร์สว่าง ฟาร์มสเตย์รูปแบบใหม่ในยโสธร ในงาน E-SAN Life drive ขับเคลื่อนอีสานสู่อนาคต​
วันนี้ผมดีใจที่ได้คำใหม่ของยโสธรมา 
ฟาร์มสเตย์ เพิ่มมาจาก โฮมสเตย์ มันก็ทำให้นึกขึ้นได้ว่าเข้าท่าดีนะ
ประชุมที่อุบล มาทำงานที่ศรีสะเกษ ไปดูสินค้าเกษตรที่อำนาจ และมาพักที่ฟาร์มสเตย์ยโสธร
</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/portfolio/portfolio-g.webp" alt="อนุทิน กล่าวชื่นชม นาจันทร์สว่าง ฟาร์มสเตย์รูปแบบใหม่ในยโสธร" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about-area ptb--40 mt--50 bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--20 align-items-center">
                                <div className="col-lg-6 col-md-12">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/portfolio/18.webp" alt="ผู้ว่ามาเยือนนาจันทร์สว่าง" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                             <h2 className="title">ผู้ว่ามาเยือนนาจันทร์สว่าง</h2> 
                                            <p className="description">เมื่อวันที่ 16 กันยายน 2567 นายชรินทร์ ทองสุข ผู้ว่าราชการจังหวัดยโสธร พร้อมด้วยคณะ ได้เดินทางมาเยี่ยมชมนาจันทร์สว่าง ฟาร์มสเตย์ยโสธร โดยฟาร์มสเตย์แห่งนี้เน้นการท่องเที่ยวเชิงเกษตร เปิดโอกาสให้นักท่องเที่ยวได้สัมผัสวิถีชีวิตเกษตรแบบดั้งเดิมในบรรยากาศท้องทุ่งนาและสวนเกษตร ผู้ว่าฯ ได้แนะนำให้ฟาร์มสเตย์แห่งนี้เป็นหนึ่งในปฏิทินกิจกรรมของจังหวัดยโสธร เพื่อส่งเสริมการท่องเที่ยวเชิงเกษตรและสร้างโอกาสให้นักท่องเที่ยวสามารถเยี่ยมชมและสัมผัสประสบการณ์ต่าง ๆ ได้อย่างเต็มที่</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about-area ptb--40 mt--50 bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--20 align-items-center">
                               
                                <div className="col-lg-6 col-md-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                             <h2 className="title">นาจันทร์สว่างฟาร์มสเตย์ยโสธร ได้รางวัล sme softpower star</h2> 
                                            <p className="description">เมื่อไม่นานมานี้ นาจันทร์สว่างฟาร์มสเตย์ ยโสธร ได้รับรางวัล “SME Softpower STAR” ซึ่งถือเป็นการยอมรับในความสามารถและความสำเร็จของฟาร์มสเตย์ในด้านการใช้ “Softpower” หรือพลังทางวัฒนธรรมและความคิดสร้างสรรค์ เพื่อสร้างคุณค่าและส่งเสริมความยั่งยืนในภาคเกษตรและการท่องเที่ยว ฟาร์มสเตย์แห่งนี้ได้ทำหน้าที่เป็นตัวอย่างที่ดีของการนำภูมิปัญญาท้องถิ่นมาผสานกับการทำธุรกิจอย่างชาญฉลาด จนได้รับรางวัลอันทรงเกียรตินี้</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/portfolio/11.webp" alt="ประวัตินาจันทร์สว่างฟาร์มสเตย์ยโสธร" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about-area ptb--40 mt--50 bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--20 align-items-center">
                                <div className="col-lg-6 col-md-12">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/portfolio/50.webp" alt="ประวัตินาจันทร์สว่างฟาร์มสเตย์ยโสธร" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                             <h2 className="title">รับรางวัล 5 ดาว STGs STAR จากการผ่านเกณฑ์ Sustainable Tourism Goals ถึง 14 ข้อจากทั้งหมด 17 ข้อ</h2> 
                                            <p className="description">นาจันทร์สว่างฟาร์มสเตย์ ยโสธร ได้รับรางวัลระดับ 5 ดาว STGs STAR จากการผ่านเกณฑ์ Sustainable Tourism Goals (STGs) ถึง 14 ข้อจากทั้งหมด 17 ข้อ รางวัลนี้เป็นเครื่องหมายการันตีความเป็นเลิศด้านการท่องเที่ยวอย่างยั่งยืน ซึ่งแสดงให้เห็นถึงความสามารถของฟาร์มสเตย์ในการบริหารจัดการทรัพยากรธรรมชาติอย่างมีประสิทธิภาพ การส่งเสริมชุมชนในพื้นที่ และการสร้างประสบการณ์ท่องเที่ยวที่มีคุณภาพและความยั่งยืนในระยะยาว</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about-area ptb--40 mt--50 bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--20 align-items-center">
                               
                                <div className="col-lg-6 col-md-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                             <h2 className="title">โคตรคูลมาถ่ายทำที่นาจันทร์สว่างฟาร์มสเตย์ ยโสธร</h2> 
                                            <p className="description">     รายการ “CAMPปลิ้น” โดยโคตรคูล ได้เดินทางมาถ่ายทำที่จังหวัดยโสธร โดยมีการเผยแพร่ตอนที่ 79 ซึ่งแบ่งเป็นสองส่วน ในตอนนี้ ทีมงานได้ร่วมกับแก๊งไทบ้าน พาผู้ชมสำรวจเมืองบั้งไฟโก้ ยโสธร  ใน EP.79 [1/2] ทีมงานได้พาผู้ชมสัมผัสบรรยากาศและวิถีชีวิตของชาวยโสธรอย่างใกล้ชิด ส่วนใน EP.79 [2/2] ทีมงานได้นั่งร่วมวงรับประทานอาหารและสนทนากับชาวบ้านในบรรยากาศที่เป็นกันเอง  ￼การถ่ายทำครั้งนี้ได้รับการตอบรับอย่างดีจากผู้ชม โดยมีผู้เข้าชมวิดีโอจำนวนมากและแสดงความคิดเห็นเชิงบวกเกี่ยวกับเนื้อหาและบรรยากาศของรายการ</p>
                                            <p className="description">     การที่รายการ “CAMPปลิ้น” โดยโคตรคูลมาถ่ายทำที่นาจันทร์สว่างฟาร์มสเตย์ ยโสธร นับเป็นโอกาสสำคัญที่ช่วยประชาสัมพันธ์ฟาร์มสเตย์ให้เป็นที่รู้จักในวงกว้าง เนื่องจากรายการนี้มีผู้ชมจำนวนมากและได้รับความนิยมอย่างแพร่หลาย การนำเสนอวิถีชีวิตชุมชนและกิจกรรมภายในฟาร์มสเตย์ผ่านมุมมองที่สนุกสนานและเข้าถึงง่าย ช่วยเสริมสร้างภาพลักษณ์ที่ดีและดึงดูดนักท่องเที่ยวที่สนใจประสบการณ์ท่องเที่ยวเชิงเกษตร นอกจากนี้ การปรากฏตัวในรายการยังเป็นการยืนยันถึงคุณภาพและความน่าสนใจของนาจันทร์สว่างฟาร์มสเตย์ในฐานะแหล่งท่องเที่ยวที่ควรค่าแก่การเยี่ยมชม
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/portfolio/4.webp" alt="โคตรคูลมาถ่ายทำที่นาจันทร์สว่างฟาร์มสเตย์ ยโสธร" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            

                {/* Start Portfolio Area */}
                <div className="area ptb--40 bg_color--1" id="portfolio">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 className="title">THANK YOU ALL PARTNER</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper sacousel-inner mb--55">
                        <div className="slick-activation mt--30 mt_sm--30">
                            <Slider {...portfolioSlick2}>
                                {PortfolioList2.map((value, index) => (
                                    <div className="im_portfolio" key={index}>
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                    {value.image}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                {/* <Footer /> */}
            </Fragment >
        )
    }
}
export default Startup;