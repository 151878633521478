// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

import PageScrollTop from './component/PageScrollTop';

// Home layout
import Home from './home/Home';

// Element Layout
import About from "./elements/About";
import Contact from "./elements/Contact";
import Product from "./elements/Product";
import GoldSaver from "./elements/GoldSaver";
import error404 from "./elements/error404";

import Bangle from "./elements/product/Bangle";
import Bracelet from "./elements/product/Bracelet";
import Cbracelet from "./elements/product/Cbracelet";
import Earring from "./elements/product/Earring";

import Jewelry99 from "./elements/product/Jewelry99";
import MadeOrder from "./elements/product/MadeOrder";
import Necklace from "./elements/product/Necklace";
import Pendant from "./elements/product/Pendant";
import Ring from "./elements/product/Ring";
import Silver from "./elements/product/Silver";
import GoldBar from "./elements/product/GoldBar";


import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import GoogleMap from './blocks/GoogleMap';

class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />

                        {/* Element Layot */}
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />
                        <Route exact path={`${process.env.PUBLIC_URL}/product`} component={Product} />
                        <Route exact path={`${process.env.PUBLIC_URL}/goldsaver`} component={GoldSaver} />


                        <Route exact path={`${process.env.PUBLIC_URL}/ring`} component={Ring} />
                        <Route exact path={`${process.env.PUBLIC_URL}/necklace`} component={Necklace} />
                        <Route exact path={`${process.env.PUBLIC_URL}/bracelet`} component={Bracelet} />
                        <Route exact path={`${process.env.PUBLIC_URL}/bangle`} component={Bangle} />
                        <Route exact path={`${process.env.PUBLIC_URL}/pendant`} component={Pendant} />
                        <Route exact path={`${process.env.PUBLIC_URL}/earring`} component={Earring} />
                        <Route exact path={`${process.env.PUBLIC_URL}/children-bracelet`} component={Cbracelet} />
                        <Route exact path={`${process.env.PUBLIC_URL}/silver`} component={Silver} />
                        <Route exact path={`${process.env.PUBLIC_URL}/jewelry99`} component={Jewelry99} />
                        <Route exact path={`${process.env.PUBLIC_URL}/made-order`} component={MadeOrder} />
                        <Route exact path={`${process.env.PUBLIC_URL}/goldbar`} component={GoldBar} />
                        <Route exact path={`${process.env.PUBLIC_URL}/googlemap`} component={GoogleMap} />




                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
                        <Route component={error404} />

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();