import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import { FaPhoneAlt, FaFacebook,FaLine ,FaMapMarkedAlt  } from "react-icons/fa";


class Contact extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='ติดต่อเรา' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />

                {/* Start Contact Top Area  */}
                <div className="contact-area ptb--50 bg_color--5">
                    <div className="contact-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title mb--30 text-center">
                                        <h3 className="title">ติดต่อสอบถามเราได้ทุกช่องทาง</h3>
                                        <p className="description">เรายินดีพร้อมให้บริการ</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="address">
                            <div className="ptb--40 bg_color--1">
                                <div className="container">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                <img src="/assets/images/contact/map-1.webp" alt="นาจันทร์สว่างฟาร์มสเตย์ยโสธร สาขา1" />
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                <h4>นาจันทร์สว่างฟาร์มสเตย์ยโสธร</h4>
                                                <p>นาจันทร์สว่าง ฟาร์มสเตย์, อำเภอเมือง, จังหวัดยโสธร, ประเทศไทย</p>
                                                <a href="tel:+6698 237 7270" class="button mt--10"><FaPhoneAlt className="icon" />โทร 0982377270</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/farmchansawangyasothon" class="button mt--15"><FaFacebook className="icon" />Facebook</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://line.me/R/ti/p/@852xpbpt?oat_content=url&ts=09131507" class="button mt--15"><FaLine className="icon" />Line</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/1qYq4WaVpGuVV6g59" class="button mt--15"><FaMapMarkedAlt className="icon" />แผนที่ Google Map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Contact Top Area  */}
                </div>


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </React.Fragment>
        )
    }
}
export default Contact